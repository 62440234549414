import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectAll", "checkbox", "selectedCount", "bulkActions"];

  connect() {
    this.selectedResponses = new Set();
    this.updateBulkActions();
  }

  toggleSelectAll(event) {
    const checked = event.target.checked;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
      this.updateSelection(checkbox);
    });
  }

  updateSelection(event) {
    const checkbox = event.target || event;
    const responseId = checkbox.dataset.response_id;

    if (checkbox.checked) {
      this.selectedResponses.add(responseId);
    } else {
      this.selectedResponses.delete(responseId);
    }

    this.updateBulkActions();
  }

  updateBulkActions() {
    const count = this.selectedResponses.size;
    this.selectedCountTarget.textContent = count;
    this.bulkActionsTarget.classList.toggle("hidden", count === 0);
  }

  performDownload(){
    if (this.selectedResponses.size === 0) return;

    // Seçili response ID'lerini virgülle birleştirerek parametre oluştur
    const responseIds = Array.from(this.selectedResponses).join(",");

    // Butonun data-url attribute'undan URL'yi al
    const url = this.element.dataset.export_url;
    console.log(this.element)
    // Yeni bir link oluşturarak dosya indirme işlemini başlat
    const downloadUrl = `${url}?response_ids=${encodeURIComponent(responseIds)}`;
    window.open(downloadUrl, '_blank');
  }

  performBulkAction() {
    if (this.selectedResponses.size === 0) return;

    const selectedIds = Array.from(this.selectedResponses);
    
    $("#submissions-to-list-modal").find('#selected-response-ids').val(selectedIds.join(','))
    $("#submissions-to-list-modal").modal("show");

    this.updateBulkActions();
  }
}
